import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { StyledH1, StyledSectionWrapper } from "../theme/styles";
import styled from "styled-components";
import t from "../theme/theme";

const StyledH1Small = styled(StyledH1)`
  font-size: ${t.spacing(2)};
`;

const TextWrapper = styled.div`
  width: calc(100% - 60px);
  word-break: break-word;
  font-family: "dolly", serif;
  color: ${t.blue};
  p:last-child {
    padding-bottom: 10vh;
  }
  @media (min-width: ${t.desktop}) {
    padding-top: 15vh;
  }
`;

const NewsPage = (props) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark;
  return (
    <Layout location={props.location}>
      <StyledSectionWrapper>
        <StyledH1Small>{data.frontmatter.title}</StyledH1Small>
        <TextWrapper>
          <ReactMarkdown children={data.rawMarkdownBody} />
        </TextWrapper>
      </StyledSectionWrapper>
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query TextQuery {
    allFile(limit: 1, filter: { sourceInstanceName: { eq: "words" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
            }
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
